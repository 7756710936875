<template>
  <punt-modal
    size="max-w-[660px] p-6 my-3 max-[575px]:px-3 bg-white"
    class="postal-code-generate-form"
    :show-close="false"
  >
    <template #title>
      <div class="pb-4">
        <h1 class="text-lg text-black w-full text-center"><b>Generate Postal Request Code</b></h1>
      </div>
    </template>

    <template #body>
      <div v-if="isLoading" class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-50">
        <div class="loader"></div>
      </div>

      <div>
        <div
          id="postalCodeGenerateForm"
          class="sm:p-12 p-4 shadow-md rounded-2xl bg-white mx-auto border-solid border-2 border-gray-100 mb-8"
        >
          <!-- Step Indicators -->
          <div class="form-header flex gap-3 mb-4 text-xs text-center">
            <span
              class="stepIndicator flex-1 pb-8 relative"
              :class="{ active: currentTab === step1, finish: completedTabs[step1] }"
            >Location Access</span>
            <span
              class="stepIndicator flex-1 pb-8 relative"
              :class="{ active: currentTab === step2, finish: completedTabs[step2] }"
            >Bioauth Verification</span>
            <span
              class="stepIndicator flex-1 pb-8 relative"
              :class="{ active: currentTab === step3, finish: completedTabs[step3] }"
            >Postal Request Code</span>
          </div>

          <!-- Steps Content -->
          <div v-show="currentTab === step1" class="step">
            <div class="flex flex-col gap-4 text-lg text-slate-300/60 font-sesmibold">
              <div class="flex justify-center items-center gap-5 mt-5">
                <img src="../assets/map.png" height="120" width="120" />
              </div>
              <div class="flex justify-center m-5">
                <ul class="list-disc ml-4 text-black text-sm">
                  <li>
                    Allow us to access your Location.
                  </li>
                </ul>
              </div>
            </div>
            <div class="text-center mt-4">
              <button
                type="button"
                id="getLocation"
                class="btn btn-primary flex-1 focus:outline-none border border-gray-300 py-2 px-5 rounded-lg shadow-sm text-center text-gray-700 hover:bg-gray-100 text-lg"
                @click="this.radarTrack()"
              >
                Allow Location
              </button>
            </div>
            <p v-if="locationError" class="text-md text-red-800 leading-tight text-center mt-8 mb-5">{{ locationError
              }}</p>
          </div>

          <div v-show="currentTab === step2" class="step">
            <div class="flex flex-col gap-4 text-lg text-slate-300/60 font-sesmibold">
              <div class="flex justify-center items-center gap-5 mt-5">
                <img src="../assets/svg/icon-face.svg" height="120" width="120" />
              </div>
              <div class="m-5">
                <ul class="list-disc ml-4 text-black text-sm">
                  <li>
                    To proceed further, please verify your identity by taking a quick selfie.
                  </li>
                  <li>
                    Postal Code generate request will be processed as soon selfie face verification is
                    verified and approved.
                  </li>
                </ul>
              </div>
            </div>
            <div class="w-full flex flex-col items-center justify-center mt-4">
              <button
                v-if="bioAuthSessionId"
                type="button"
                id="getLocation"
                @click="this.processBioAuth()"
                class="btn-bet hover:opacity-75 disabled:opacity-50 min-w-[200px] text-black/90"
              >
                Verify Your Identity
              </button>
            </div>
            <p v-if="bioAuthError" class="text-md text-red-800 leading-tight text-center mt-8 mb-5">{{ bioAuthError
              }}</p>
          </div>

          <div v-show="currentTab === step3" class="step">
            <p class="text-md text-gray-700 leading-tight text-center mt-8">Your Postal Request Code</p>
            <div class="flex justify-center mt-8">
              <img alt="Success Icon" class="max-w-[60px]" src="../assets/success-icon.png" />
            </div>
            <div class="flex justify-center mt-8">
              <input
                type="text"
                disabled
                placeholder="Your Postal Request Code"
                @contextmenu.prevent
                @copy.prevent
                name="postalCode"
                v-model="postalCode"
                id="protectedElement"
                class="md:w-[240px] w-3/4 p-2 text-2xl rounded-md text-center text-gray-700 font-medium border-solid border-2 border-gray-200 no-select"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';
import Radar from 'radar-sdk-js';
import { fraudAndLocationPassed, proxyLocation } from '@/lib/utils';
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { doRecaptcha } from '@/lib/recaptcha';
import axios from '@/lib/axiosConfig';
import 'radar-sdk-js/dist/radar.css';
import { mapGetters } from 'vuex';
import { copyToClipboard } from '@/lib/utils';

export default {
  name: 'PostalCodeMultiStepForm',
  components: {
    PuntModal,
  },
  data() {
    return {
      currentTab: 'geo_location',
      completedTabs: [],
      step1: 'geo_location',
      step2: 'bio_auth',
      step3: 'postal_code',
      locationError: null,
      bioAuthError: null,
      radarId: null,
      bioAuthUrl: null,
      bioAuthSessionId: null,
      postalCode: null,
      fetchBioAuthResponseInterval: null,
      isLoading: false,
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    radarTrack() {
      if (this.isLoggedIn) {
        this.isLoading = true;
        this.locationError = '';
        this.bioAuthError = '';

        try {
          Radar.trackOnce()
            .then((result) => {
              // const {user} = result;
              // if (proxyLocation(user)) {
              //   this.locationError = 'Access to our platform is not available for VPN connections. Please disable your VPN and try again. If you believe you\'re receiving this message in error, or if you need assistance, please contact our support team at: help@chanced.com';
              // } else if (!fraudAndLocationPassed(user)) {
              //   this.locationError = 'Your location is restricted from accessing the website. If you believe this is a mistake, please email: help@chanced.com';
              // } else {
              this.radarId = result?._id ?? result?.user?._id;
              if (result?.user?.locationAuthorization === 'DENIED') {
                this.locationError = 'Location services must be enabled to generate a postal request code.';
              } else {
                this.completedTabs[this.step1] = true;
                this.currentTab = this.step2;
                this.getPostalCode();
              }
              // }
            })
            .catch((err) => {
              let error = err.toString();
              if (error.includes('RadarLocationPermissionsError') || error.includes('RadarPermissionsError')) {
                this.locationError = 'Location services must be enabled to generate a postal request code.';
              } else if (error.includes('RadarLocationError')) {
                this.locationError = 'Unable to determine your location. Please try again or contact support';
              } else if (error.includes('RadarRateLimitError')) {
                this.locationError = 'Too many requests. Please try after sometime.';
              } else {
                this.locationError = 'Something went wrong, fresh the page and try again.';
                console.log('radar error', error);
              }
            });
        } catch (error) {
          console.log('login with radar is failed');
        } finally {
          this.isLoading = false;
        }
      } else {
        window.swal(
          'Cannot Generate Postal Request Code',
          'You need to login for that.',
          'error',
        );
      }

    },
    async getPostalCode() {
      try {
        this.isLoading = true;
        doRecaptcha('user_postal_request_code')
          .then(async (recaptcha) => {
            const response = await axios.post('/user-postal-request-code', {
              recaptcha,
              radar_id: this.radarId,
              session_id: this.bioAuthSessionId,
            });

            if (response?.data?.result?.session_id) {
              this.bioAuthSessionId = response?.data?.result?.session_id;
            }

            if (response?.data?.result?.next_step) {
              this.currentTab = response?.data?.result?.next_step;
              if (this.currentTab === this.step2) {
                this.completedTabs[this.step1] = true;
              }
            }

            if (response?.data?.result?.code) {
              clearInterval(this.fetchBioAuthResponseInterval);
              this.completedTabs[this.step1] = true;
              this.completedTabs[this.step2] = true;
              this.completedTabs[this.step3] = true;
            }

            this.postalCode = response?.data?.result?.code ?? null;
            this.bioAuthUrl = response?.data?.result?.url ?? null;

            this.isLoading = false;
          })
          .catch((e) => {
            const errorType = e.data?.result?.error_type;
            if (errorType === 'veriff_idv') {
              this.$emit('update-idv-status', false);
            } else if (errorType === 'ssn') {
              this.$emit('update-ssn-status', false);
            } else if (errorType === 'frozen') {
              this.bioAuthError = e.data?.error;
              this.bioAuthSessionId = null;
              clearInterval(this.fetchBioAuthResponseInterval);
            } else if (errorType === 'bio_auth') {
              this.bioAuthError = e.data?.error;
              clearInterval(this.fetchBioAuthResponseInterval);
            } else if (errorType === 'bio_auth_failed') {
              this.bioAuthError = e.data?.error;
              clearInterval(this.fetchBioAuthResponseInterval);
              this.bioAuthSessionId = null;
            } else {
              this.$toast.error('Something went wrong. Please try again.');
              console.log('error', e);
            }

            this.isLoading = false;
          });
      } catch (error) {
        console.log('Issue while generating postal code');
      }
    },
    processBioAuth() {
      if (this.bioAuthUrl) {
        createVeriffFrame({
          url: this.bioAuthUrl,
          onEvent: function(msg) {
            switch (msg) {
              case MESSAGES.CANCELED:
                break;
              case MESSAGES.FINISHED:
                this.isLoading = true;
                this.fetchBioAuthResponseInterval = setInterval(() => this.getPostalCode(), 3000);
                break;
            }
          }.bind(this),
        });
      }
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  mounted: function() {
    let radar_config = {
      logLevel: import.meta.env.VITE_NODE_ENV === 'production' ? 'error' : 'info',
      cacheLocationMinutes: 0,
    };
    Radar.initialize(import.meta.env.VITE_RADAR_KEY, radar_config);
  },
};
</script>

<style scoped>
.postal-code-generate-form {
  position: relative;
}

.postal-code-generate-form .close-punt-modal {
  color: black !important;
  outline: none !important;
}

#postalCodeGenerateForm .form-header .stepIndicator.active {
  font-weight: 600;
}

#postalCodeGenerateForm .form-header .stepIndicator.finish {
  font-weight: 600;
  color: #40ab07;
}

#postalCodeGenerateForm .form-header .stepIndicator::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 9;
  width: 20px;
  height: 20px;
  background-color: #c3dafe;
  border-radius: 50%;
  border: 3px solid #ebf4ff;
}

#postalCodeGenerateForm .form-header .stepIndicator.active::before {
  background-color: #3d67c1;
  border: 3px solid #c3dafe;
}

#postalCodeGenerateForm .form-header .stepIndicator.finish::before {
  background-color: #40ab07;
  border: 3px solid #ceecbe;
}

#postalCodeGenerateForm .form-header .stepIndicator::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 8px;
  width: 100%;
  height: 3px;
  background-color: #a3bffa;
}

#postalCodeGenerateForm .form-header .stepIndicator.active::after {
  background-color: #a3bffa;
}

#postalCodeGenerateForm .form-header .stepIndicator:last-child:after {
  display: none;
}

#postalCodeGenerateForm input.invalid {
  border: 2px solid #ffaba5;
}

.postal-code-generate-form .loader {
  width: 70px;
  height: 70px;
  margin: 50%;
  border: 5px solid #64696a;
  border-bottom-color: #7162f1;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation-ebb7d3cb 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.no-select::selection {
  background: transparent;
}
</style>
