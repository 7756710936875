import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';
import { timeParse } from 'd3-time-format';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const authStore = defineStore('auth', {
  state: () => {
    return {
      claims: [],
    };
  },
  getters: {},
  actions: {
    async resetPassword({ email, recaptcha }) {
      return await axios.post('/forgot-password', { email, recaptcha });
    },
    async updatePassword({ token, email, password, password_confirmation, recaptcha }) {
      return await axios.post('/reset-password', {
        email,
        token,
        password,
        password_confirmation,
        recaptcha,
      });
    },
    async verify() {
      return await axios.post('/veriff');
    },
    async fetchVerify() {
      return await axios.get('/veriff');
    },
  },
});
