<template>
  <div class="md:flex rounded-b-md">
    <div v-if="this.loading">
      <Loader class="fixed inset-0 z-[9999] flex justify-center items-center flex-col" />
    </div>

    <div class="hidden md:flex-auto md:w-2/4 md:block">
      <img src="../../assets/login.webp" class="login-r-banner" alt="login" />
    </div>
    <div
      class="flex-col md:px-16 md:py-20 sm:px-14 sm:py-18 md:w-2/4 md:flex md:flex-auto p-6">
      <form @submit.stop.prevent="() => radarTrackAndLogin()">
        <label
          for="email-address"
          class="block mt-4 mb-2 text-sm font-medium text-text-dim-color"
          >Email or Username</label
        >
        <div class="relative">
          <div
            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803ZM7.56569 8.17538C7.11026 7.86296 6.4878 7.97889 6.17538 8.43431C5.86296 8.88974 5.97889 9.5122 6.43431 9.82462L10.3029 12.4785C11.3256 13.18 12.6744 13.18 13.6971 12.4785L17.5657 9.82462C18.0211 9.5122 18.137 8.88974 17.8246 8.43431C17.5122 7.97889 16.8897 7.86296 16.4343 8.17538L12.5657 10.8293C12.2248 11.0631 11.7752 11.0631 11.4343 10.8293L7.56569 8.17538Z"
                fill="white" />
            </svg>
          </div>
          <input
            v-model="form.username"
            required
            type="text"
            id="email-address"
            class="input-with-icon focus:ring-accent"
            placeholder="Your email or username here" />
        </div>

        <label
          for="email-address"
          class="block mt-4 mb-2 text-sm font-medium text-text-dim-color"
          >Password</label
        >
        <div class="relative">
          <div
            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.16146 9H15.8385C16.3657 8.99998 16.8205 8.99997 17.195 9.03057C17.5904 9.06287 17.9836 9.13419 18.362 9.32698C18.9265 9.6146 19.3854 10.0735 19.673 10.638C19.8658 11.0164 19.9371 11.4096 19.9694 11.805C20 12.1795 20 12.6344 20 13.1615V17.8386C20 18.3657 20 18.8205 19.9694 19.195C19.9371 19.5904 19.8658 19.9836 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.9836 21.8658 17.5904 21.9371 17.195 21.9694C16.8205 22 16.3657 22 15.8385 22H8.16145C7.63431 22 7.17954 22 6.80498 21.9694C6.40963 21.9371 6.01641 21.8658 5.63803 21.673C5.07355 21.3854 4.6146 20.9265 4.32698 20.362C4.13419 19.9836 4.06287 19.5904 4.03057 19.195C3.99997 18.8205 3.99998 18.3657 4 17.8386V13.1614C3.99998 12.6343 3.99997 12.1795 4.03057 11.805C4.06287 11.4096 4.13419 11.0164 4.32698 10.638C4.6146 10.0735 5.07355 9.6146 5.63803 9.32698C6.01641 9.13419 6.40963 9.06287 6.80498 9.03057C7.17953 8.99997 7.63434 8.99998 8.16146 9ZM12 15C11.4477 15 11 15.4477 11 16V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V16C13 15.4477 12.5523 15 12 15Z"
                fill="white" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6V9H14V6C14 4.89543 13.1046 4 12 4C10.8954 4 10 4.89543 10 6V9H8V6Z"
                fill="white" />
            </svg>
          </div>
          <input
            v-model="form.password"
            required
            type="password"
            id="email-password"
            class="input-with-icon focus:ring-accent"
            placeholder="Your password here" />
        </div>

        <button class="mt-8 btn-bet">Sign In</button>
      </form>

      <div
        class="flex flex-row items-center hidden w-full mt-4 space-x-2 text-xs text-text-dim-color">
        <span class="flex-1 border-b border-line-color" />
        <span>Or</span>
        <span class="flex-1 border-b border-line-color" />
      </div>

      <button
        class="flex flex-row items-center justify-center hidden w-full p-3 mt-4 space-x-2 font-semibold text-white bg-secondary-light hover:opacity-70 rounded-xl"
        v-on:click.prevent="googleLogin()">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.4251 8.13952C17.3637 7.67736 16.952 7.35591 16.4858 7.35591H9.95553C9.40324 7.35591 8.95553 7.80363 8.95553 8.35591V9.80691C8.95553 10.3592 9.40324 10.8069 9.95553 10.8069H13.1356C13.4518 10.8069 13.6903 11.0982 13.586 11.3967C12.9474 13.2243 11.203 14.2599 8.95553 14.2599C8.26186 14.2611 7.57479 14.1253 6.93371 13.8604C6.29263 13.5955 5.71016 13.2066 5.21971 12.716C4.72926 12.2255 4.34048 11.6429 4.07566 11.0018C3.81085 10.3607 3.67521 9.67358 3.67653 8.97991C3.67534 8.28633 3.81108 7.59934 4.07595 6.95833C4.34083 6.31731 4.72963 5.73489 5.22006 5.24445C5.7105 4.75402 6.29292 4.36521 6.93394 4.10034C7.57495 3.83546 8.26194 3.69973 8.95553 3.70091C9.88645 3.70091 10.7512 3.94531 11.4997 4.36819C11.947 4.62092 12.5222 4.6023 12.8856 4.23908L14.0881 3.03709C14.4977 2.62758 14.4772 1.95184 14.0002 1.62322C12.5641 0.633923 10.8439 0.0469131 8.95553 0.0469131C7.78133 0.0434809 6.61805 0.272223 5.53258 0.719983C4.4471 1.16774 3.46086 1.82569 2.63058 2.65597C1.8003 3.48625 1.14235 4.47249 0.694595 5.55796C0.246835 6.64343 0.0180933 7.80672 0.0215254 8.98091C0.0179605 10.1551 0.246612 11.3185 0.694325 12.404C1.14204 13.4896 1.79997 14.4758 2.63028 15.3062C3.46059 16.1365 4.44689 16.7944 5.53242 17.2421C6.61795 17.6898 7.7813 17.9185 8.95553 17.9149C13.4225 17.9149 17.4845 14.6659 17.4845 8.98091C17.4845 8.70764 17.4628 8.42339 17.4251 8.13952Z"
            fill="white" />
        </svg>
        <span>Sign In With Google</span>
      </button>

      <router-link
        to="/password/forgot"
        class="mt-6 text-sm font-semibold text-center text-white block">
        Forgot password?
      </router-link>

      <p class="mt-10 text-center text-white">
        Don’t have an account?
        <a
          href="#"
          v-on:click="$router.replace({ path: $route.path, query: { tab: 'register' } })"
          :class="{
            '!text-white rounded-t-lg border-b-2 border-[#ff3860]':
              activeTab === 'register',
          }"
          class="text-accent">
          Register Here
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { doRecaptcha } from '@/lib/recaptcha';
import Radar from 'radar-sdk-js';
import { fraudAndLocationPassed, proxyLocation } from '@/lib/utils';
import Loader from '@/components/Shared/Loader.vue';

const MAX_REDIRECT_AGE = 30 * 60 * 1000; // 30 minutes in milliseconds

export default {
  name: 'LoginForm',
  components: { Loader },
  data() {
    return {
      form: {
        username: '',
        password: '',
        token: '',
      },
      widget: false,
      loading: false,
      timeoutId: null,
      loginInProgress: false
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    async login() {
      try {
        // Clear the timeout if it exists
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
          this.timeoutId = null;
        }

        if(this.loginInProgress) return;

        this.form.recaptcha = await doRecaptcha('login');
        this.form.custom_ip = (document.getElementById('custom_ip') != null)?document.getElementById('custom_ip').value:'0.0.0.0';
        this.loginInProgress = true;
        await this.performLogin(this.form);
        this.handlePostLoginRedirect();
      } catch (e) {
        console.log(e);
        window.swal('Failed to login', 'Reload page and try again.', 'error');
      } finally {
        this.loading = false;
        this.loginInProgress = false;
      }
    },
    handlePostLoginRedirect() {
      if (this.isLoggedIn) {
        const redirectRoute = this.$store.state.auth.redirectRoute;
        const redirectTimestamp = this.$store.state.auth.redirectTimestamp;
        const redirectIsRecent = (Date.now() - redirectTimestamp) < MAX_REDIRECT_AGE;
        this.$store.commit('clear-redirect-route');

        if (redirectRoute && redirectIsRecent) {
          const { path, query } = this.$router.resolve(redirectRoute);
          this.$router.push({ path, query });
        } else {
          this.$router.push({ path: '/' });
        }
      }
    },
    radarTrackAndLogin() {
      this.loading = true;
      const GEO_TIMEOUT = 8000;
      this.timeoutId = setTimeout(() => {
        this.login();
        //this.$root.geoLocationPermissionEvent('Please turn on your location permission/service. Email us if you are still having issues at: help@chanced.com', false); // Todo: API feature implementation
      }, GEO_TIMEOUT);

      try {
        Radar.trackOnce()
          .then((result) => {
            if(this.loginInProgress) return;

            this.login();

            // Todo: API feature implementation
            // const {user} = result;
            //
            // if (proxyLocation(user)) {
            //   let message = 'Access to our platform is not available for VPN connections. Please disable your VPN and try again. If you believe you\'re receiving this message in error, or if you need assistance, please contact our support team at: help@chanced.com';
            //   this.$root.geoLocationPermissionEvent(message, this.isLoggedIn , false, true);
            // } else if (!fraudAndLocationPassed(user)) {
            //   let message = 'Your location is restricted from accessing the website. If you believe this is a mistake, please email: help@chanced.com';
            //   this.$root.geoLocationPermissionEvent(message, this.isLoggedIn);
            // } else {
            //   this.login()
            // }
          })
          .catch((err) => {
            if(this.loginInProgress) return;

            this.login();

            // Todo: API feature implementation
            //
            // let error = err.toString();
            //
            // if (error.includes('RadarLocationPermissionsError')) {
            //   this.$root.geoLocationPermissionEvent('Please allow location permission/service to continue. If you need additional help, please email: help@chanced.com', false, true);
            // }
            // else if (error.includes('RadarLocationError')) {
            //   this.$root.geoLocationPermissionEvent('Unable to determine your location, please try again. If you need additional help, please email: help@chanced.com', false, true);
            // }
            // else if (error.includes('RadarRateLimitError')) {
            //   window.swal('Failed to login', 'Please try again.', 'error')
            // } else {
            //   this.login();
            // }
          });
      } catch (error) {
        console.log('login with radar is failed');
      }
    },
    googleLogin() {
      this.$gAuth
        .signIn()
        .then((GoogleUser) => {
          return this.performGoogleLogin(GoogleUser.getAuthResponse()['access_token']);
        })
        .then(() => {
          this.$router.push('/');
        })
        .catch((err) => {
          if (err.message) {
            window.swal('', err.message, 'error');
          }
        });
    },
    ...mapActions(['performLogin', 'performGoogleLogin']),
  },
};
</script>
