import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';
import { timeParse } from 'd3-time-format';
import { doRecaptcha } from '@/lib/recaptcha';
// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const dailyBonusStore = defineStore('dailybonus', {
 state: () => ({
     
      selected: {
        name: null,
        icon: null,
      },
      dailybonuses :
      { 
        0: {
           
          gc : "5000",
          sc : "0.3",
        },
        1: {
           
          gc : "5000",
          sc : "0.3",
        },
        2: {
           
          gc : "5000",
          sc : "0.3",
        },
        3: {
          
         
          gc : "10000",
          sc : "0.5",
        },
        4: {
          
         
          gc : "20000",
          sc : "1",
        },
        5:{
          
          gc : "20000",
          sc : "1",
        },
        6:{
          gc : "20000",
          sc : "1",
        },
      }
    
  }),
  getters: {
    getAvailableDailbonus: (state) => state.dailybonuses,
  },
  actions: {
    async claim() {
      const recaptcha = await doRecaptcha('daily_bonus');
      return await axios.post('/hourly-bonus', { fp: window.fp, recaptcha });
    },
    async lastClaimedBonuses() {
      return await axios.get('/hourly-bonus?fp=' + window.fp);
    },

    async lastClaimedGcTopup() {
      return await axios.get('/getlastgctopup');
    },
    async claimGCtopup() {
      const recaptcha = await doRecaptcha('daily_bonus');
      return await axios.post('/claimgctopup', { recaptcha });
    },
  },
});
